import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { map, Observable } from 'rxjs';
import { RoundUpPipe } from '../../../@common/util/round-up.pipe';

@Component({
    selector: 'app-pagination',
    templateUrl: './pagination.component.html',
    styleUrls: [ './pagination.component.scss' ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        FaIconComponent,
        AsyncPipe,
        RoundUpPipe
    ],
})
export class PaginationComponent {
    @Input({ required: true }) totalItems!: number | undefined;
    @Input({ required: true }) pageSize!: number | undefined;
    currentPage$: Observable<number>;
    faArrowLeft = faArrowLeft;
    faArrowRight = faArrowRight;

    constructor(
        private router: Router,
        private route: ActivatedRoute
    ) {
        this.currentPage$ = this.route.queryParamMap.pipe(
            map(params => params.get('page') ?? 1),
            map(page => +page)
        );
    }

    toPage(page: number) {
        this.router.navigate([], {
            queryParams: { page },
            queryParamsHandling: 'merge',
        });
    }

}
