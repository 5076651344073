@if (currentPage$ | async; as currentPage) {
    @if (((totalItems ?? 1) / (pageSize ?? 1)) | roundUp; as availablePages) {
        @if (availablePages > 1) {
            <div class="pagination d-flex mt-4 line">
                <div class="arrows" (click)="toPage(currentPage - 1)" [class.hide]="currentPage <= 1">
                    <fa-icon [icon]="faArrowLeft" class="me-1" />
                    Previous
                </div>
                <div class="d-flex">
                    <div class="number">Page {{ currentPage }} of {{ availablePages }}</div>
                </div>
                <div class="arrows" (click)="toPage(currentPage + 1)" [class.hide]="availablePages <= currentPage">
                    Next
                    <fa-icon [icon]="faArrowRight" class="ms-1" />
                </div>
            </div>
        }
    }
}
